export const EMPLOYMENT_TYPES = [
  { id: 1, name: 'Salaried' },
  { id: 2, name: 'Self Employed Professional' },
  { id: 3, name: 'Self Employed Non-professional' },
  { id: 4, name: 'Retired' },
  { id: 5, name: 'Other' }
];

export const GENDER_TYPES = [
  { id: 1, name: 'Male' },
  { id: 2, name: 'Female' },
  { id: 3, name: 'Others' }
];

export const CITY_TYPE = [
  { id: 1, name: 'Mumbai' },
  { id: 2, name: 'Bangalore' },
  { id: 3, name: 'Pune' },
  { id: 4, name: 'Others' }
];

export const MARITAL_STATUS = [
  { id: 1, name: 'Married' },
  { id: 2, name: 'Single' },
  { id: 3, name: 'Widowed' },
  { id: 4, name: 'Separated' },
  { id: 5, name: 'Divorced' }
];

export const RELATIONSHIP = [
  { id: 1, name: 'Mother' },
  { id: 2, name: 'Father' },
  { id: 3, name: 'Spouse' },
  { id: 4, name: 'Brother' },
  { id: 5, name: 'Sister' },
  { id: 6, name: 'Other' }
];

export const GENDER_ENUMS = {
  male: 1,
  female: 2,
  other: 3
};

export const STATUS = {
  PUBLISHED: 'PUBLISHED',
  CREATED: 'CREATED'
};

export const GENDER_ENUMS_NEW = [
  { name: 'Male', id: 1, src: '/images/male.png' },
  { name: 'Female', id: 2, src: '/images/female.png' }
];

export const INPUT_ICONS = {
  'money-bag': {
    height: '25px',
    width: '19px',
    src: '/images/money-bag.png'
  },
  'cake-icon': {
    height: '25px',
    width: '23px',
    src: '/images/cake.png'
  },
  'location-icon': {
    height: '25px',
    width: '14px',
    src: '/images/location.png'
  },
  'envelope-icon': {
    height: '25px',
    width: '25px',
    src: '/images/letter.png'
  },
  'mailbox-icon': {
    height: '25px',
    width: '26px',
    src: '/images/mailbox.png'
  },
  'building-icon': {
    height: '25px',
    width: '26px',
    src: '/images/building.png'
  }
};

export const STEPS_TO_NAVIGATE = {
  profile_loan_detail: '/loan-requirement',
  profile_detail: '/personal-detail',
  bureau_score: '/offers',
  document_upload: '/document-upload',
  congratulations: '/congratulations'
};

export const APPLICATION_PATHS_TO_NAVIGATE = {
  '/loan-requirement': 'profile_loan_detail',
  '/personal-detail': 'profile_detail',
  '/offers': 'bureau_score',
  '/document-upload': 'document_upload',
  '/congratulations': 'congratulations'
};

export const PROFILE_STEPS = {
  profile_loan_detail: 'profile_loan_detail',
  profile_detail: 'profile_detail',
  bureau_score: 'bureau_score',
  document_upload: 'document_upload',
  congratulations: 'congratulations'
};

export const SETP_COMPLETATION_PERCENTAGE = {
  application_personal: 30,
  application_professional: 30,
  application_property: 30,
  application_documents: 5,
  application_offers: 5
};

export const OUR_PRODUCTS = [
  {
    id: 1,
    name: 'New Home Loan',
    description: 'Make your dream home a reality with Home Loan interests as low as 6.75%*',
    checkPoints: ['100% DIGITAL', 'LOW RATES'],
    buttonTitle: 'Get Started',
    imageUrl: 'icons/product-1.svg',
    events: {
      'data-category': 'Customer Signup',
      'data-action': 'Get New Home Loan',
      'data-label': '/signup'
    }
  },
  {
    id: 2,
    name: 'Transfer Home Loan',
    description: 'Save by shifting your exisiting Home Loan at a better interest rate*',
    checkPoints: ['EASY TRANSFER', 'BETTER RATES'],
    buttonTitle: 'Get Started',
    imageUrl: 'icons/product-2.svg',
    events: {
      'data-category': 'Customer Signup',
      'data-action': 'Transfer Home Loan',
      'data-label': '/signup'
    }
  },
  {
    id: 3,
    name: 'Loan Against Property',
    description: 'Put your property to use by getting the most attractive interest rate*',
    checkPoints: ['BEST DEALS', 'LOW RATES'],
    buttonTitle: 'Get Started',
    imageUrl: 'icons/product-3.svg',
    events: {
      'data-category': 'Customer Signup',
      'data-action': 'Get Loan Against Property',
      'data-label': '/signup'
    }
  }
];

export const THREE_GRID_DATA = [
  {
    name: 'Digital Applications',
    description: 'Easiloan integrates with banking partners for end-to-end & easy loan processing online & digitally',
    imageUrl: 'icons/partnership-1.svg'
  },
  {
    name: 'Market Aggregation',
    description: 'Access to high quality prequalified customer profiles across segments, loan values and geographies',
    imageUrl: 'icons/partnership-2.svg'
  },
  {
    name: 'Productivity Multiplier',
    description: 'Higher conversion of leads processed from Easiloan application as opposed to traditional ways',
    imageUrl: 'icons/partnership-3.svg'
  }
];

export const HOME_LOAN_FEATURES = [
  {
    name: 'Digital Home Loans',
    description: 'Complete your Loan Application at your convenience from anywhere, anytime!',
    imageUrl: 'icons/platform-1.svg'
  },
  {
    name: 'choice of Banks',
    description: 'New way of accessing banks on single platform instead of going to various banks',
    imageUrl: 'icons/platform-2.svg'
  },
  {
    name: 'Quick & Easy',
    description: 'Save time with our digital application instead of paper-based process',
    imageUrl: 'icons/platform-3.svg'
  },
  {
    name: 'Customized Offers',
    description: 'Benefit from proprietary AI based Instant match-making tool that generates customized offers',
    imageUrl: 'icons/platform-4.svg'
  }
];

export const HOW_IT_WORKS = {
  customers: [
    {
      title: '1. Sign up & Apply',
      description: 'Sign Up/ Register with us in seconds and share your loan requirements with Easiloan',
      imageUrl: 'icons/works-1.svg'
    },
    {
      title: '2. Compare Options',
      description: 'Get customized options by our proprietary AI-based Instant match making tool',
      imageUrl: 'icons/works-2.svg'
    },
    {
      title: '3. Select Best Offer ',
      description: 'Choose one offer from multiple options that suits your home loan requirements the best',
      imageUrl: 'icons/works-3.svg'
    },
    {
      title: '4. Relax & Sit Back',
      description: 'Sit back and relax as we work on getting your home loan processed at the earliest',
      imageUrl: 'icons/works-4.svg'
    }
  ],
  developers: [
    {
      title: '1. Register with us',
      description: 'Partner with Easiloan under ‘Easiloan Developer Program’ and upload leads',
      imageUrl: 'icons/developers-1.svg'
    },
    {
      title: '2. CRM & Onsite Support',
      description: 'Dedicated RM and CRM team will assist you through the process',
      imageUrl: 'icons/developers-2.svg'
    },
    {
      title: '3. Improve Profitability',
      description: 'Early cashflows. High retention with faster home loans and better control on customers',
      imageUrl: 'icons/developers-3.svg'
    },
    {
      title: '4. Business Multiplier',
      description: 'Benefit through preferred schemes and bank tie-ups',
      imageUrl: 'icons/developers-4.svg'
    }
  ],
  channelPartners: [
    {
      title: '1. Register with us',
      description: 'Partner with Easiloan and get rewarded as per ‘Easiloan Partner Program’',
      imageUrl: 'icons/channel-1.svg'
    },
    {
      title: '2. Upload Leads',
      description: 'Upload your leads through Partner login in a user-friendly format',
      imageUrl: 'icons/channel-2.svg'
    },
    {
      title: '3. CRM Support',
      description: 'Dedicated RM and CRM team will assist you through the process',
      imageUrl: 'icons/channel-3.svg'
    },
    {
      title: '4. Dashboard Tracking',
      description: 'Know the status of customer application',
      imageUrl: 'icons/channel-4.svg'
    }
  ]
};

export const landingPageNews = [
  {
    id: 1,
    title: 'TIMES OF INDIA',
    description: 'EasiLoan partners top housing financiers, digitises sanctions- Times of India',
    link: 'https://timesofindia.indiatimes.com/business/india-business/easiloan-partners-top-housing-financiers-digitises-sanctions/articleshow/83414587.cms?utm_source=whatsapp&utm_medium=social&utm_campaign=TOIMobile',
    logoUrl: '/images/toi.png'
  },
  {
    id: 2,
    title: 'Bloomberg',
    description:
      "Easiloan Launches India's 1st Home loan selection and recommendation engine for home buyers using AI based application",
    link: 'https://www.bloomberg.com/press-releases/2021-07-07/easiloan-launches-india-s-1st-home-loan-selection-and-recommendation-engine-for-home-buyers-using-ai-based-application',
    logoUrl: '/images/bloomberg.png'
  },
  {
    id: 3,
    title: 'Economic Times',
    description: 'Home loans fintech platform Easiloan raises Rs 8 crore from Tomorrow Capital',
    link: 'https://economictimes.indiatimes.com/industry/banking/finance/home-loans-fintech-platform-easiloan-raises-rs-8-crore-from-tomorrow-capital/articleshow/86234015.cms?from=mdr',
    logoUrl: '/images/et.png'
  },
  {
    id: 4,
    title: 'TIMES OF INDIA',
    description: 'Easiloan picks up seed funding from Tomorrow Capital',
    link: 'https://timesofindia.indiatimes.com/business/india-business/easiloan-picks-up-seed-funding-from-tomorrow-capital/articleshow/85960015.cms',
    logoUrl: '/images/toi.png'
  },
  {
    id: 5,
    title: 'YOURSTORY',
    description: 'After 3 months of stealth, this startup aims to make home loans digital, raises Rs 8 Cr',
    link: 'https://yourstory.com/2021/09/funding-home-loans-startup-digital-tomorrow-capital-easiloan/amp',
    logoUrl: '/images/your-story.png'
  },
  {
    id: 6,
    title: 'Outlook MONEY',
    description: 'Home Loan Ecosystem Experience Technological Innovation',
    link: 'https://www.outlookindia.com/outlookmoney/expenses/home-loan-ecosystem-experience-technological-innovation-8268',
    logoUrl: '/images/outlook-money.png'
  },
  {
    id: 7,
    title: '99acres',
    description: 'Easiloan to process digital home loans through AI-based recommendation platform in Pune',
    link: 'https://www.99acres.com/articles/easiloan-to-process-digital-home-loans-through-ai-based-recommendation-platform-in-pune.html',
    logoUrl: '/images/99acres.png'
  },
  {
    id: 8,
    title: '99acres',
    description: 'How Artificial Intelligence is changing the real estate landscape',
    link: 'https://www.99acres.com/articles/how-artificial-intelligence-is-changing-the-real-estate-landscape.html',
    logoUrl: '/images/99acres.png'
  },
  {
    id: 9,
    title: '99acres',
    description: 'Factors to consider before availing a home loan',
    link: 'https://www.99acres.com/articles/factors-to-consider-to-avail-the-best-home-loan.html',
    logoUrl: '/images/99acres.png'
  },
  {
    id: 10,
    title: '99acres',
    description: '10 smart tips on choosing the right lender for housing finance',
    link: 'https://www.99acres.com/articles/10-smart-tips-on-choosing-the-right-lender-for-housing-finance.html',
    logoUrl: '/images/99acres.png'
  },
  {
    id: 11,
    title: 'ConstructionWeek',
    description: 'I believe that we are finally on the highway to growth',
    link: 'https://www.constructionweekonline.in/people/i-believe-that-we-are-finally-on-the-highway-to-growth',
    logoUrl: '/images/cwo-logo.png'
  },
  {
    id: 12,
    title: 'Financial Express',
    description: 'Why is it important to pay your EMIs on time?',
    link: 'https://www.financialexpress.com/money/why-is-it-important-to-pay-your-emis-on-time/2400624/',
    logoUrl: '/images/financialexpress.png'
  },
  {
    id: 13,
    title: 'Firstpost',
    description: 'Budget 2022: Why budgetary outlook needs to be accretive towards real estate, fintech',
    link: 'https://www.firstpost.com/business/budget-2022-why-budgetary-outlook-needs-to-be-accretive-towards-real-estate-fintech-10303751.html',
    logoUrl: '/images/fp-desk-logo.png'
  },
  {
    id: 14,
    title: 'CNBCTV18',
    description: 'Home buying momentum to boost home loan market in 2022',
    link: 'https://www.cnbctv18.com/finance/home-loan-outlook-2022-interest-rate-home-buying-momentum-to-boost-home-loans-12245272.htm',
    logoUrl: '/images/cnbctv18-logo.png'
  }
];

export const BANK_OFFERS = [
  '/images/pnb.png',
  '/images/icici@2x.png',
  '/images/bajaj.png',
  '/images/hdfc-hl.png',
  '/images/cboi.png',
  '/images/lic.png',
  '/images/sbi-home-loan.png',
  '/images/iifl-home-loan.png',
  '/images/axis.png'
];

export const TESTIMONIALS = [
  {
    name: 'Samit Nair',
    feedback:
      '“It was very helpful of you to support on the financial planning and process to acquire the property. We were hopeless and about to drop the plan until you gave us assurance of benefiting us with the maximum credit allowance from the financial institutions. It was your financial knowledge that helped us to take a leap of faith. Thanks for the support you have provided. We are waiting for your positive energy towards fulfilling the desired task from the institution.”',
    reply: '',
    imageUrl: 'icons/partnership-1.svg',
    applideFor: ''
  },
  {
    name: "Delzon D'silva",
    feedback:
      '“You have been very approachable and have explained and clarified all our queries to our satisfaction. Thank you Mehul and we look forward to your continued support.”',
    reply: '',
    imageUrl: 'icons/partnership-1.svg',
    applideFor: ''
  },
  {
    name: 'Rajesh Varadarajan',
    feedback:
      '“You have been very proactive, customer &amp; service oriented. He assisted in getting low interest rate on home loan from HDFC Ltd. I wish him all the best.”',
    reply: '',
    imageUrl: 'icons/partnership-1.svg',
    applideFor: ''
  },
  {
    name: 'Piyush D Bhanushali',
    feedback:
      '“I would like to thank Pinank for getting my loan sanctioned during the lockdown when I was a little skeptical because no documents were available handy as entire India was closed. He gave me the confidence and went an extra mile to assist me at any given point of time so that I did not have to cancel plans for my own first home.”',
    reply: '',
    imageUrl: 'icons/partnership-1.svg',
    applideFor: ''
  },
  {
    name: 'Ruchita Patil',
    feedback:
      '“You have been very approachable and have explained and clarified all our queries to our satisfaction. Thank you very much Akshay and we look forward to your continued support. It was very good interacting with you received quick response from you when required because of your timely action we have got our home.”',
    reply: '',
    imageUrl: 'icons/partnership-1.svg',
    applideFor: ''
  }
];

export const CONTACT_FORM_TYPE = ['customers', 'developers', 'banks'];

export const LENDERS = [
  {
    id: 1,
    name: 'ICICI',
    url: 'https://www.icicibank.com/Personal-Banking/loans/home-loan/documents-required.page'
  },
  {
    id: 2,
    name: 'HDFC',
    url: 'https://www.hdfc.com/checklist/documents-charges'
  },
  {
    id: 3,
    name: 'LIC',
    url: 'https://www.lichousing.com/Home_Loan/Housing_Loan.php'
  },
  {
    id: 4,
    name: 'IIFL',
    url: 'https://www.iiflhomeloans.com/home-loan/documents'
  },
  {
    id: 5,
    name: 'Axis',
    url: 'https://www.axisbank.com/retail/loans/home-loan/axis-bank-home-loan/eligibility-documentations'
  },
  {
    id: 6,
    name: 'Bajaj Fineserve',
    url: 'https://www.bajajfinserv.in/documents-required-for-home-loan'
  },
  {
    id: 7,
    name: 'CBI',
    url: 'https://www.bankbazaar.com/central-bank-of-india-home-loan.html'
  },
  {
    id: 8,
    name: 'L & T HF',
    url: 'https://www.ltfs.com/companies/lnt-finance/lnt-housing-finance/products/document-checklist.html'
  },
  {
    id: 9,
    name: 'PNB',
    url: 'https://www.pnbhousing.com/home-loan/documents-required/'
  },
  {
    id: 10,
    name: 'IDFC',
    url: 'https://www.idfcfirstbank.com/personal-banking/loans/home-loan/documentation'
  },
  {
    id: 11,
    name: 'Kotak',
    url: 'https://www.kotak.com/en/personal-banking/loans/home-loan/required-documents.html'
  },
  {
    id: 12,
    name: 'SBI Home Loans',
    url: 'https://homeloans.sbi/products/view/regular-home-loan'
  }
];

export const EKYC_DOCUMENT_TYPES = [
  {
    title: 'eKYC',
    type: 'uidai-portal'
  },
  {
    title: 'Upload Aadhar',
    type: 'upload'
  },
  {
    title: 'Scan QR code',
    type: 'qr-code'
  }
];

export const EKYC_PERSONAL_INFO = {
  slug: {
    slug_type_ekyc: 'aadhaar-card',
    slug_type_qrcode: 'qr-code'
  }
};

export const KYC_STEPS = {
  enter_aadhar_no: 1,
  enter_otp: 2,
  scan_qr_code: 3,
  details: 4,
  edit_details: 5,
  button: {
    edit_details: 'Edit details',
    restart_kyc_verification: 'Restart KYC verification'
  }
};

export const BANK_STATEMENT_TYPES = [
  {
    title: 'Net Banking',
    type: 'internet-banking'
  },
  {
    title: 'Bank Statement (PDF)',
    type: 'upload'
  },
  {
    title: 'Bank Statement (PDF)',
    type: 'bank-statement-source'
  }
];

export const ITR_TYPES = [
  {
    title: 'IT Portal',
    type: 'it-portal'
  },
  {
    title: 'Upload',
    type: 'upload'
  }
];

export const TAB_TYPE_BANK_STATEMENT = 'radio';
export const TAB_TYPE_EKYC = 'radio';

export const FILE_TYPES = [
  {
    id: 1,
    type: 'pdf',
    accept: 'application/pdf'
  },
  {
    id: 2,
    type: 'image',
    accept: 'image/jpg,image/jpeg,image/png'
  },
  {
    id: 3,
    type: 'imageAndPdf',
    accept: 'image/jpg,image/jpeg,image/png,application/pdf'
  }
];

export const STEPS = [
  { id: 1, name: 'Basic Details' },
  { id: 2, name: 'Personal Information' },
  { id: 3, name: 'Explore Loan Offers' },
  { id: 4, name: 'Document Verification' },
  { id: 5, name: 'Eligibility Advice Letter' }
];

export const STEPS_ENUM = {
  profile_loan_detail: 0,
  profile_detail: 1,
  bureau_score: 2,
  document_upload: 3,
  congratulations: 4
};

export const DOCUMENT_UPLOAD_STEPS = [
  {
    name: 'Bank statement',
    slug: 'bank-statement'
  },
  {
    name: 'Salary Slip',
    slug: 'salary-slip'
  },
  {
    name: 'Pancard',
    slug: 'pancard'
  },
  {
    name: 'Utility Bills',
    slug: 'utility-bills'
  },
  {
    name: 'Form 16',
    slug: 'form16'
  },
  {
    name: 'Photo',
    slug: 'photo'
  }
];

export const OFFER_TYPE = {
  offer: 1,
  eligible_offer: 2
};

export const OPTIONAL_DOCUMENTS = ['board-resolution'];

export const PROOF_OF_RELATIONSHIP = [
  { id: 1, name: 'Marraige Certificate' },
  { id: 2, name: 'Birth Certificate' },
  { id: 3, name: 'Wedding Cards Photo' },
  { id: 4, name: 'Other' }
];

export const OFFER_DEPENDENT_ROUTES = ['document_upload', 'congratulations'];

export const APPLICATION_STATUS = [
  { id: 'New', name: 'New' },
  { id: 'Qualified', name: 'Qualified' },
  { id: 'Duplicate', name: 'Duplicate' },
  { id: 'Rejected', name: 'Rejected' },
  { id: 'Processing', name: 'Processing' },
  { id: 'Sanction', name: 'Sanction' },
  { id: 'Disbursed', name: 'Disbursed' }
];

export const SOURCE = [
  { id: 'Channel Partner', name: 'Channel Partner' },
  { id: 'Developer', name: 'Developer' },
  { id: 'Inbound call', name: 'Inbound call' },
  { id: 'Referral', name: 'Referral' },
  { id: 'Social Media', name: 'Social Media' },
  { id: 'Sole Seller', name: 'Sole Seller' },
  { id: 'Whatsapp', name: 'Whatsapp' },
  { id: 'Website', name: 'Website' }
];

export const PARTNER_RELATIONSHIP = [
  {
    id: 'Father',
    name: 'Father'
  },
  {
    id: 'Mother',
    name: 'Mother'
  },
  {
    id: 'Son',
    name: 'Son'
  },
  {
    id: 'Daughter',
    name: 'Daughter'
  },
  {
    id: 'Spouse',
    name: 'Spouse'
  },
  {
    id: 'Friend',
    name: 'Friend'
  },
  {
    id: 'Brother',
    name: 'Brother'
  },
  {
    id: 'Sister',
    name: 'Sister'
  },
  {
    id: 'Other',
    name: 'Other'
  }
];
